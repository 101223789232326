<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails d'une catégorie : {{ serviceCategory ? serviceCategory.translations.fr ? serviceCategory.translations.fr.value : '-' : '-' }}
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <h4>Description de la catégorie</h4>
            <br>
            <v-text-field
              v-model="value[currentLocale]"
              label="Nom"
              class="mb-5"
              outlined
              dense
              placeholder="Nom"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <h4>Sélection de service</h4>
            <br>
            <v-select
              v-model="serviceSelection"
              :items="services"
              multiple
              outlined
              chips
              hint="Choisir les campings à associées"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'serviceCategoryList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraOutline, mdiMinusBox, mdiPencil, mdiCompassRose, mdiThumbUp, mdiSkiWater } from '@mdi/js'
import config from '../../../config'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    LocalSwitcher,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      services: [],
      serviceSelection: [],
      id: [],
      value: [],
      serviceCategory: null,
      entityData: {
        id: null,
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {}
      }
    }
  },
  created () {
    this.getServices()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putServiceCategory()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getServices () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/services', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const services = response.data

            for (let i = 0; i < services.length; i++) {
              const currentService = services[i]

              if (currentService.translations.fr !== undefined) {
                this.services.push({
                  text: currentService.translations.fr.value,
                  value: currentService.id
                })
              }

            }
          }

          this.getServiceCategory()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getServiceCategory () {
      const serviceCategoryId = this.$route.params.serviceCategoryId

      this.loading = true


      Vue.prototype.$http
        .get(config.apiUrl + '/api/service/categories/' + serviceCategoryId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.serviceCategory = response.data
            this.entityData.id = this.serviceCategory.id

            this.entityData.services = this.serviceCategory.services

            for (let i = 0; i < this.entityData.services.length; i++) {
              const currentService = this.entityData.services[i]

              this.serviceSelection.push({
                value: currentService.id
              })
            }

            const translations = this.serviceCategory.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.value[currentKey] = currentTranslation.value
            }

            this.valid = true

            if (this.entityData.mainImage.id) {
              this.mainImagePreview = URL.createObjectURL(config.apiUrl + '/media/' + this.entityData.mainImage.id)
            }
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putServiceCategory () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      this.handleTranslations([
        'id',
        'value'
      ])

      const selectedServices = []

      for (let i = 0; i < this.serviceSelection.length; i++) {
        const currentService = this.serviceSelection[i]

        selectedServices.push({
          id: (typeof currentService === 'string') ? currentService : currentService.value
        })
      }

      this.entityData.services = selectedServices

      Vue.prototype.$http
        .put(config.apiUrl + '/api/service/categories/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.serviceCategory = response.data

            const translations = this.serviceCategory.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.value[currentKey] = currentTranslation.value
            }

            this.snackbar = true
            this.successMessage = 'Catégorie mise à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>

