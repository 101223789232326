<template>
  <div>
    <div class="mt-2 mb-5 mr-2 float-right">
      <span @click="updateCurrentLocale('fr')">
        <country-flag
          class="mr-1"
          country="fr"
          size="normal"
          shadow
        />
      </span>
      <span @click="updateCurrentLocale('en')">
        <country-flag
          class="mr-1"
          country="gb"
          size="normal"
          shadow
        />
      </span>
      <span @click="updateCurrentLocale('nl')">
        <country-flag
          class="mr-1"
          country="nl"
          size="normal"
          shadow
        />
      </span>
      <span @click="updateCurrentLocale('de')">
        <country-flag
          class="mr-1"
          country="de"
          size="normal"
          shadow
        />
      </span>
    </div>

    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: 'LocalSwitcher',
  methods: {
    updateCurrentLocale (locale) {
      this.$emit('updateCurrentLocale', locale)
    }
  }
}
</script>
